<template>
  <q-layout view="hHh lpR fFf">
    <q-header class="bg-dark">
      <q-toolbar
        ><q-btn
          flat
          dense
          color="secondary"
          label="RED SKIES"
          size="xl"
          to="/"
        />
        <q-space />
        <div class="q-pa-sm q-gutter-sm">
          <q-btn
            label="Discord"
            color="secondary"
            type="a"
            href="https://discord.gg/2YbFhkQZ"
            target="_blank"
            size="lg"
            padding="0px 10px"
            flat
            disable
          />
          <q-btn
            label="Rules"
            color="secondary"
            size="lg"
            padding="0px 10px"
            flat
            to="rules"
          />
          <q-btn
            label="History"
            color="secondary"
            size="lg"
            padding="0px 10px"
            flat
            to="history"
          />
          <q-btn
            label="Apply"
            color="secondary"
            size="lg"
            padding="0px 10px"
            flat
            to="apply"
            disable
          />
        </div>
      </q-toolbar>
    </q-header>
    <q-space />
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
//import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "LayoutDefault",

  components: {
    // HelloWorld,
  },

  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style lang="sass">
@font-face
  font-family: Headline
  src: url("./assets/fonts/header-45.ttf")

@font-face
  font-family: SourceSerifPro
  src: url("./assets/fonts/SourceSerifPro-Regular.woff")

body.body--dark
  background-color: #202020 !important
  background-image: url("./assets/bg_002.png") !important
  background-repeat: no-repeat !important
  background-size: 100% !important
  margin: 0 auto
  height: 100%
  font-family: SourceSerifPro
  font-size: 1.2em
  color: #CCC !important

a
  color: #fff
  text-decoration: none

a:hover
  color: #F00

.text-caption
  font-size: 0.8em !important

.content
  width:800px
  margin: 0 auto

.logo
  padding-top: 50px
  padding-bottom: 50px
  display: block
  margin-left: auto
  margin-right: auto
.text-h1
  font-family: Headline
  text-shadow: 2px 2px #000
.q-btn
  font-family: Headline !important
</style>
