<template>
  <div class="content">
    <img class="logo" src="../assets/WelcomeToDorado.png" />
    <p>
      Red Skies Role Play is an Red Dead Redemption 2 roleplay server using the
      RedM Server. A place that is welcoming and inclusive of all types of
      players and fosters excellence in roleplay and creativity. In this world
      you will have the chance to play your favorite archetype of the old west
      and engage in dynamic and meaningful Role Play with the rest of the
      community.
    </p>
    <p>
      Our writters have worked hard to create a living and breathing world full
      of Lore and History for your characters to hook into. Through the comming
      seasons we will introduce new story lines driven by players and the
      outside world. An ever changing landscape for you to tell your story in.
    </p>
    <img class="logo" src="../assets/JoinTheDiscord.png" />
    <p>
      The fastest way to get started is to join our community discord and then
      come back to this page and start your application! We use an allow-list to
      ensure only the best RP possible and keep out persons set on disruption or
      fail-rp.
    </p>
    <img class="logo" src="../assets/HistoryOfDorado.png" />
    <p>
      Dive into the lore written and developed by our creative staff to learn
      more about the history of Dorado and how your characters can fit into the
      world!
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="sass">
.content
 width: 800px
 margin: 0 auto
</style>
